import React from "react";
import '../constants/video.css'
import styled from "styled-components"
import Title from '../components/Title'
import { injectIntl, FormattedMessage} from "gatsby-plugin-intl"



const Video = ({ title, videoSrcURL, videoTitle}) => {

  let predica = <FormattedMessage id = "pred"/>;

  return(
  <>
  <Title title = {predica}></Title>
  <div className="video">
    <iframe className = "frame"
      src={videoSrcURL}
      title={videoTitle}
      allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
      width = '100%'
      height = '100%'
    />
    <a href = "https://youtube.com/fereyes7" target = "_blank" rel="noreferrer" >
      <Button><FormattedMessage id = "masPred"/></Button>
    </a>
  </div>
  
  </>
  )
}


export default injectIntl(Video)

const Button = styled.button`
display: block;
width: 14rem;
height: 3.1rem;
text-align: center;
margin: 0 auto;
margin: 3rem auto 0;
`