import React from "react"
import styled from "styled-components"
import Image from 'gatsby-image'
import { Link } from "gatsby"
import { injectIntl, FormattedMessage } from "gatsby-plugin-intl"


let contactanos = <FormattedMessage id="contactanos" />
let seminarios = <FormattedMessage id="sems" />
let subtitle = <FormattedMessage id = "subtitulo_index"/>
const HeroIndex = ({image}) =>{

return(
    <HeroHeader>
        <HeroCenter>
          <HeroInfo>
            <HeroImage fluid = {image.childImageSharp.fluid}/>
            <Name>Reyes Ortiz Ministries</Name>
            <SubTitle>{subtitle}</SubTitle>
              <HButton>
                <HeroButton><HeroLink to = '/contactanos'>{contactanos}</HeroLink></HeroButton> 
                <HeroButton><HeroLink to = '/seminarios'>{seminarios}</HeroLink></HeroButton>
              </HButton>
          </HeroInfo>
        </HeroCenter>
    </HeroHeader>
)

}


const HeroLink = styled(Link)`
  color: white;
`

const HeroButton = styled.button`
  padding: 1rem;
  border-radius: 2px;
  margin-right:1rem;
  border-color: #ffffff;
  background: none;
  color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: ${({ theme }) => theme.radius};
  :hover {
    color: ${({ theme }) => theme.colorPrimary5};
    background: ${({ theme }) => theme.colorPrimary5};
  }
  @media screen and (min-width: 992px) {
    grid-row: 1/1;
    grid-column: 1 / span 8;
  }
  @media screen and (min-width: 1170px) {
    grid-column: 1 / span 8;
  }
`


const Name = styled.h1`
/*   

  position: relative;
  display: inline;
  z-index: 3;
  text-align: center;
 
  top: 50%;
  left: 38%;
  */
  /* text-align: left; */
  margin: 0 auto;
  align-items: center;
  z-index: 2;
  position: relative;
  top: -50%;
  font-family: ${({ theme }) => theme.thirdFont};
  font-size: 5rem;
  color: ${({ theme }) => theme.colorWhite};
 
  @media screen and (max-width: 768px) {
    font-size: 2.3rem;
  }
 
`
const HButton = styled.div`
 margin: 0 auto;
  align-items: center;
  z-index: 2;
  position: relative;
  top: -50%;
  margin-top:2rem;

`
const SubTitle = styled.h3`
  /*

  position: relative;
  display: inline;
  z-index: 3;
  text-align: center;
 
  top: 50%;
  left: 38%;
  */
  /* text-align: left; */
  margin: 0 auto;
  align-items: center;
  z-index: 2;
  position: relative;
  top: -50%;
  font-size: 1.5rem;
 

  color: ${({ theme }) => theme.colorWhite};
 /*
  @media screen and (max-width: 768px) {
    font-size: 2.3rem;
  }
  */
`

const HeroImage = styled(Image)`

  height: 86vh;
  width: 100%;
  z-index: 1;
  filter: brightness(85%);
`

const HeroInfo = styled.div`
  height: 100vh;
  width: 100%;
  position: relative;
  text-align: center;
  color: white;
  @media screen and (min-width: 992px) {
    grid-row: 1/1;
    grid-column: 1 / span 8;
  }
  @media screen and (min-width: 1170px) {
    grid-column: 1 / span 8;
  }
 
`


const HeroHeader = styled.header`
  height: 66vh;
  margin: 0 auto;
  justify-content: center;
  background: ${({ theme }) => theme.background};
  
  @media screen and (min-width: 992px) {
  }
  @media screen and (min-width: 1170px) {
    font-size: 1rem;
  }
`
const HeroCenter = styled.div`
  height: 66vh;
  width: 100%;
  /* left: 20%; */
  position: relative;
  text-align: left;
  /*
  margin: 0 auto;
  */
  /*
  max-width: 1170px;
  */
  @media screen and (min-width: 992px) {
  }
`



export default injectIntl(HeroIndex)