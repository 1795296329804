import React from "react"
import Title from "../components/Title"
import styled from "styled-components"
import HorizontalBlock from "../components/HorizontalBlock"
import { injectIntl, FormattedMessage, Link } from "gatsby-plugin-intl"

const HorizontalEvent = ({ data, intl }) => {
  const { edges: dataQuery } = data.allContentfulEvent
  let seminarioTitle = <FormattedMessage id="proximoSem" />

  return (
    <>
      <SectionEvent>
        <EventCenter>
          <Title title={seminarioTitle}></Title>
          {dataQuery.map((event, index) => {
            let eventInfo = event.node
            return (
              <>
                {eventInfo.featured === true ? (
                  <>
                    <HorizontalBlock key={eventInfo.id} {...eventInfo} />
                    <Link to="/seminarios">
                      {/* <Button><FormattedMessage id="sem" /></Button> */}
                    </Link>
                  </>
                ) : (
                  <div></div>
                )}
              </>
            )
          })}
        </EventCenter>
      </SectionEvent>
    </>
  )
}

const SectionEvent = styled.section`
  padding-bottom: 2rem;
  padding-top: 10rem;
`
const EventCenter = styled.section`
  width: 80vw;
  left: 20%;
  max-width: 1170px;
  margin: 0 auto;
  align-items: center;
`
const Button = styled.button`
  display: block;
  width: 14rem;
  height: 3.1rem;
  text-align: center;
  margin: 0 auto;
  margin: 3rem auto 0;
`

export default injectIntl(HorizontalEvent)
