import React from "react"
import Layout from "../components/Layout.js"
import Video from "../components/Video"
import styled from "styled-components"
import HorizontalEvent from "../constants/HorizontalEvent"
import ArticlesMenu from "../constants/ArticlesMenu"
import SEO from "../components/SEO"
import { graphql } from "gatsby"
// import Hero from "../components/HeroThree"
import Hero from "../components/HeroFour"
// import Hero from "../components/HeroTwo"
import HeroIndex from "../components/HeroIndex"
import { injectIntl, FormattedMessage, Link } from "gatsby-plugin-intl"


const Home = ({ data }) => {
  const title = "New Title"
  return (
    <>
      <SEO title="Home" description="Home Page" />
      <Layout>
        <Div>
          <HeroIndex image={data.file} />
          <HorizontalEventDiv>
            <HorizontalEvent data={data} />
          </HorizontalEventDiv>
          <Video videoSrcURL="https://www.youtube.com/embed?listType=user_uploads&list=fereyes7" />
          <ArticlesMenu data={data} />
        </Div>
      </Layout>
    </>
  )
}

const Div = styled.div``
const HorizontalEventDiv = styled.div`
  padding-top: 6rem;
`

export const query = graphql`
  query ContentfulEvent($locale: String) {
    allContentfulEvent(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          image {
            fluid {
              src
            }
            title
          }
          title
          description {
            description
          }
          id
          date
          address
          node_locale
          featured
        }
      }
    }

    #Image Here

    file(relativePath: { eq: "beautiful-sky.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    allContentfulBlogPost(
      filter: { featured: { eq: true }, node_locale: { eq: $locale } }
    ) {
      nodes {
        title
        id
        slug
        description {
          description
        }
        createdAt
        heroImage {
          fluid {
            src
          }
        }
      }
    }
  }
`
export default injectIntl(Home)
