import React from "react"
import styled from "styled-components"
import Video from "../images/video4.mp4"
import { Link, useStaticQuery, graphql } from "gatsby"
import { injectIntl, FormattedMessage } from "gatsby-plugin-intl"
import Image from 'gatsby-image'



let contactanos = <FormattedMessage id="contactanos" />
let seminarios = <FormattedMessage id="sems" />

const HeroThree = ({ blog, content, image }) => {
  return (
    <Hero img = {image}>
      
      {/* <AVideo controls loop muted preLoad="auto" autoPlay="autoplay">
        <source src={Video} type="video/mp4" />
      </AVideo> */}
      <HeroImage fluid={image.childImageSharp.fluid}></HeroImage> 
      <Caption>
        <H1>LIBERACION ONLINE</H1>
        <H2>Sanidad Y Liberacion  Mediante Jesucristo </H2>
        {/* <H3>Y a los Cautivos  </H3> */}
        <HeroButtonWrapper>
          <HeroButton><HeroLink to = '/contactanos'>{contactanos}</HeroLink></HeroButton> <HeroButton2><HeroLink to = '/seminarios'>{seminarios}</HeroLink></HeroButton2>
        </HeroButtonWrapper>
      </Caption>
    </Hero>
  )
}


const HeroImage = styled(Image)`


  /* height: 90%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  object-fit: cover; */

`

const HeroLink = styled(Link)`
  color: white;
`

const Hero = styled.div`
  background-image: url(${props => props.img});
  display: flexbox;
  height: 80vh;
  width: 90%;
  background: none;
  align-items: center;
  justify-content: center;
  margin-left:1rem;
  &::after{
    width: 100%;
    height: 90%;
    content: '';
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.3);
  }

  @media screen and (min-width: 992px) {
    grid-row: 1/1;
    grid-column: 1 / span 8;
  }
  @media screen and (min-width: 1170px) {
    grid-column: 1 / span 8;
  }
`

const HeroButton2 = styled.button`
  padding: 1rem;
  border-radius: 2px;
  margin-right: 0rem;
  border-color: #ffffff;
  background: none;
  color: white;
  @media screen and (min-width: 992px) {
    grid-row: 1/1;
    grid-column: 1 / span 8;
  }
  @media screen and (min-width: 1170px) {
    grid-column: 1 / span 8;
  }

`

const Caption = styled.div`
  position: relative;
  z-index: 20;
  /* text-align: center; */
`

const H2 = styled.h3`
  color:white;
`
const H1 = styled.h2`
  color:white;
`
const H3 = styled.h4`
  color:white;
`

const AVideo = styled.video`
  /* min-width: 100%; */
  /* min-height: 100%; */
  /* width: auto; */
  /* height: auto; */
  /* z-index: 0; */
  /* height: 80vh; */
  /* width: 100%; */
  /* filter: brightness(60%); */
  height: 90%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  object-fit: cover;
`

const HeroButton = styled.button`
  padding: 1rem;
  border-radius: 2px;
  margin-right:1rem;
  border-color: #ffffff;
  background: none;
  color: white;
  @media screen and (min-width: 992px) {
    grid-row: 1/1;
    grid-column: 1 / span 8;
  }
  @media screen and (min-width: 1170px) {
    grid-column: 1 / span 8;
  }
`

const HeroButtonWrapper = styled.div`
  display: flex;
  margin-top: 2rem;
`


export default injectIntl(HeroThree)
