import React from 'react'
import Block from '../components/Block'
import styled from "styled-components"
import Title from '../components/Title'
import { injectIntl, FormattedMessage , Link } from "gatsby-plugin-intl"



const ArticlesMenu = ({data , intl}) => {
  //Destructuring 
    const {nodes: articleData} = data.allContentfulBlogPost;
    let articulosTitle = <FormattedMessage id = "art"/>;
    
    return (
        <>
        <Title title = {articulosTitle}></Title>
            <Section>
                <ArticlesCenter>
                {articleData.map( (dataSet , index) => {
                      const {id} = articleData[index];
                      const {title} = articleData[index];
                      const {description} = articleData[index].description;
                      const {fluid} = articleData[index].heroImage;
                      const {slug} = articleData[index];                  
                    return (
                        <Block key = {id} title={title} desc= {description} image = {fluid} slug = {'articulos/'+slug}></Block>
                        
                    )
                })}
                </ArticlesCenter>
            <Link to = "/articulos">
            <Button><FormattedMessage id = "masArt"/></Button>
            </Link>
            </Section>

        </>
    )
}

/* Styled Components */

const Section = styled.section`
  padding: 5rem 0;
`

const ArticlesCenter = styled.div`
  width: 90vw;
  height: 100%;
  margin: 0 auto;
  max-width: 1170px;
  @media screen and (min-width: 576px) {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(368px, 1fr));
    column-gap: 2rem;
  }
  @media screen and (min-width: 992px) {
    width: 95vw;
  }
`
const Button = styled.button`
display: block;
width: 14rem;
height: 3.1rem;
text-align: center;
margin: 0 auto;
margin: 3rem auto 0;
`


export default injectIntl(ArticlesMenu)
